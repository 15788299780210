import moment from 'moment-timezone';
import { Range } from 'react-date-range';
import isNumber from 'lodash/isNumber';
import { RootStateType } from 'types/store';

const getCurrentYear = () => {
  return moment().format('YYYY');
};

const getTime = (dateString: string) => {
  return moment(dateString).toDate().getTime();
};

const toString = (timeStamp: string | number | Date, format: string) => {
  return moment(timeStamp).format(format);
};

const isoStringToMilliseconds = (iso: string) => moment(iso).valueOf();

const getXDayFromDate = (xDay: number, from?: Date) => {
  const momentTime = from ? moment(from).utc() : moment().utc();
  return momentTime.startOf('day').subtract(xDay, 'days').toDate();
};

const dateRangeToString = (range: Range, format = 'DD MMM YYYY') => {
  if (range.startDate && range?.endDate) {
    return `${toString(range.startDate, format)} - ${toString(
      range.endDate,
      format,
    )}`;
  }
  return undefined;
};

const isSameDay = (
  firstTime: string | number | Date,
  secondTime: string | number | Date,
) => {
  return moment(firstTime).isSame(secondTime, 'day');
};

const toUtcString = (timeStamp: string | number | Date, format: string) => {
  return moment(timeStamp).utc().format(format);
};

const parseTimeToDetail = (time: number | undefined) => {
  if (time === undefined) return '';

  return moment.duration(time, 's').format('d[d] h[h] m[m] s[s]', {
    trim: 'both mid',
  });
};

const parseTimeToDays = (time?: number) => {
  if (time === undefined) return '';

  return `${moment.duration(time, 's').asDays().toFixed(2)} Days`;
};

const parseTimeToHours = (time?: number) => {
  if (time === undefined) return '';

  return `${moment.duration(time, 's').asHours().toFixed(2)} Hours`;
};

const formatDateWithFallback = (
  timestamp: string | number | Date,
  format: string,
  fallback: string,
) => {
  const isValidDate = moment(timestamp).isValid();
  return isValidDate ? moment(timestamp).format(format) : fallback;
};

const expressCalculateTimeToMinute = (time?: number) => {
  if (time === undefined || !isNumber(time)) return '';
  if (time < 60) return '0 minute';
  let displayDate = moment
    .duration(time, 'seconds')
    .format('d [days] h [hours] m [minutes]', {
      trim: 'all',
    });
  displayDate = displayDate.replace(/\bhour(s)?/, '$& x 60 minutes;');
  displayDate = displayDate.replace(/\bday(s)?/, '$& x 24 hours x 60 minutes;');
  const tempArr = displayDate
    .split(';')
    .map((item) => item.trim())
    .filter((item) => item !== '');
  displayDate = tempArr.join(' + ');
  return `${displayDate} = ${moment.duration(time, 'seconds').format('m __')}`;
};

const getFirstDayOfPastNumberYear = (numberYear: number) => {
  const currentYear = moment().utc().year();
  return moment.utc([currentYear - numberYear, 0, 1, 0, 0, 0, 0]).toDate();
};

const formatHourToEndOfDayWithISOStringFormat = (
  dateInput: string | Date | number,
) => {
  return new Date(new Date(dateInput).setHours(23, 59, 59, 999)).toISOString();
};

const formatHourToStartOfDayWithISOStringFormat = (
  dateInput: string | Date | number,
) => {
  return new Date(new Date(dateInput).setHours(0, 0, 0, 0)).toISOString();
};

const formatHourToUTCStartOfDayWithISOStringFormat = (
  dateInput: string | Date | number,
) => {
  return moment(dateInput)
    .utc()
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      milliseconds: 0,
    })
    .toISOString();
};

const formatHourToUTCEndOfDayWithISOStringFormat = (
  dateInput: string | Date | number,
) => {
  return moment(dateInput)
    .utc()
    .set({
      hour: 23,
      minute: 59,
      second: 59,
      milliseconds: 999,
    })
    .toISOString();
};

const getStartOrderDateFromState = (rootState: RootStateType, args?: any) => {
  const { purchaseOrderReducer, activityLogReducer } = rootState;
  const { startOrderDate } = purchaseOrderReducer;
  const { transformationInfo } = activityLogReducer;

  if (
    moment(args?.startOrderDate || startOrderDate).isAfter(
      transformationInfo?.startDate,
      'day',
    )
  ) {
    return moment(args?.startOrderDate || startOrderDate)
      .startOf('day')
      .toISOString();
  }
  return moment(transformationInfo?.startDate).startOf('day').toISOString();
};

const getUTCDate = (date: Date) => {
  return moment(date).utcOffset(date?.getTimezoneOffset(), true).toDate();
};

const getCurrentUTCEndDateWithISOStringFormat = () => {
  return moment().utc().endOf('day').toISOString();
};

const getDateWithSpecificTimeZone = (
  date: Date | string,
  timeZone: string,
): Date => {
  return moment.tz(date, timeZone).toDate();
};

export default {
  getCurrentYear,
  getTime,
  toString,
  isoStringToMilliseconds,
  getXDayFromDate,
  dateRangeToString,
  isSameDay,
  toUtcString,
  parseTimeToDetail,
  parseTimeToDays,
  parseTimeToHours,
  formatDateWithFallback,
  expressCalculateTimeToMinute,
  getFirstDayOfPastNumberYear,
  formatHourToEndOfDayWithISOStringFormat,
  formatHourToStartOfDayWithISOStringFormat,
  getStartOrderDateFromState,
  getUTCDate,
  formatHourToUTCStartOfDayWithISOStringFormat,
  formatHourToUTCEndOfDayWithISOStringFormat,
  getCurrentUTCEndDateWithISOStringFormat,
  getDateWithSpecificTimeZone,
};
