export enum PurchaseOrderFieldEnum {
  REFERENCE_NUMBER = 'referenceNumber',
  SUPPLIER_NAME = 'supplierName',
  DESCRIPTION = 'description',
  LINE_ITEMS = 'lineItems',
  ORDER_DATE = 'orderDate',
  FIRST_TAG_CHECKED_IN_TIME = 'firstTagCheckedInTime',
  ESTIMATED_DELIVERY_DATE = 'estimatedDeliveryDate',
  DELIVERY_DATE = 'deliveryDate',
  STATUS = 'status',
  ORDER_QUANTITY = 'totalOrderedQuantity',
  TAG_QUANTITY = 'totalTaggedQuantity',
  PARTS_CHECKED_OUT = 'totalQuantityCheckedOut',
  PARTS_CHECKED_IN = 'totalQuantityCheckedIn',
  VESSEL_NAME = 'vesselName',
  PENDING_CHECKED_IN_DAY = 'pendingCheckedInDays',
}

export enum PurchaseOrderDetailFieldEnum {
  INDEX = 'itemOrder',
  SPARE_NAME = 'spareName',
  ORDER_QTY = 'orderedQuantity',
  CHECKIN_QTY = 'relatedEpc',
  RELATED_EPC = 'relatedEpc',
  PARTS_CHECKED_OUT = 'totalObsoleteQuantity',
  PARTS_CHECKED_IN = 'totalCheckedInQuantity',
  TAG_QUANTITY = 'quantity',
}

export enum LineItemTagQuantityEnum {
  TAGGED = 'tagged',
  CHECKED_IN = 'checkedIn',
  CHECKED_OUT = 'checkedOut',
}

export enum LineItemTagStatusStrickenOutEnum {
  DELETE_TAGGED = 'Delete Tagged',
  DELETE_CHECKED_IN = 'Delete Checked In',
  OFFLINE_TAGGED = 'Offline Tagged',
}

export enum PurchaseOrderStatusLabelEnum {
  Pristine = 'Not Tagged',
  PartialTagged = 'Partially Tagged',
  Tagged = 'Completely Tagged',
  CheckedIn = 'Completely On Board',
  PartialCheckedIn = 'Partially On Board',
  Consumed = 'Completely Consumed',
  PartialConsumed = 'Partially Consumed',
}

export enum ReadListPurchaseOrderParamsTypeEnum {
  Pristine = 'Pristine',
  PartialTagged = 'Partial Tagged',
  Tagged = 'Tagged',
  CheckedIn = 'Checked In',
  PartialCheckedIn = 'Partial Checked In',
  Consumed = 'Consumed',
  PartialConsumed = 'Partial Consumed',
}

export enum PurchaseOrderStatTypeEnum {
  PARTIAL_TAGGED_COUNT = 'partialTaggedCount',
  COMPLETE_TAGGED_COUNT = 'completeTaggedCount',
  PARTIAL_CHECKED_IN_COUNT = 'partialCheckedInCount',
  COMPLETE_CHECKED_IN_COUNT = 'completeCheckedInCount',
  COMPLETE_CHECKED_OUT_COUNT = 'completeCheckedOutCount',
  PO_PER_DAY = 'poPerDay',
}

export enum PurchaseOrderDescriptionEnum {
  NON_SPARE_PO = 'This PO does not contain parts.',
  PRE_TRANSFORMATION_PO = 'This PO was ordered pre-transformation.',
}
