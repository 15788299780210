export enum SnackBarMessageEnum {
  DownLoadDataSuccess = 'Export request received! An email will be sent to you at',
}

export enum SnackBarMessageTypeEnum {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}
