/**
 * Default values for notification messages
 */

import { SnackBarMessageTypeEnum } from 'enums/SnackBarEnum';
import { SnackBarType } from '../../types/snackBar';

// Authentication
export const AUTHORIZATION_NO_LOGIN_PERMISSION_ERROR_NOTIFICATION: SnackBarType = {
  message: 'You do not have permission to log in.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const AUTHORIZATION_ERROR_NOTIFICATION: SnackBarType = {
  message: 'Authorization Error. Invalid Username/ Password.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const AUTHORIZATION_SERVICE_UNREACHABLE_NOTIFICATION: SnackBarType = {
  message: 'Unable to contact authorization service',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const PROFILE_NOT_FOUND: SnackBarType = {
  message: 'Unable to get the user profile or the user is invalid.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const VESSSELS_NOT_FOUND: SnackBarType = {
  message: 'Unable to get the vessels that user can access.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const RESET_PASSWORD_ERROR_NOTIFICATION: SnackBarType = {
  message: 'Unable to send email',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const RESET_PASSWORD_CONFIRM_ERROR_NOTIFICATION: SnackBarType = {
  message: 'Unable to save the password',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const VALIDATE_RESET_PASSWORD_ERROR_NOTIFICATION: SnackBarType = {
  message: 'Unable to validate the reset token',
  messageType: SnackBarMessageTypeEnum.Error,
};
// Purchase Order
export const PURCHASE_ORDER_READ_STATS_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving purchase order stats. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const PURCHASE_ORDER_READ_LIST_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving purchase order list. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const PURCHASE_ORDER_READ_LIST_BY_SPARE_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving purchase order list by spare. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const PURCHASE_ORDER_DOWNLOAD_DATA_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue while downloading purchase order list. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

// Inventory
export const INVENTORY_READ_LIST_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving inventory list. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const INVENTORY_DOWNLOAD_DATA_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue while downloading inventory list. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const INVENTORY_READ_PRODUCT_HIERARCHY_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving product hierarchy. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const INVENTORY_READ_PRODUCT_DETAIL_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving product detail. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};
export const INVENTORY_READ_PRODUCT_SUP_EQM_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving product suppllier and equipment. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};
export const INVENTORY_READ_INVENTORY_STATISTICS_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving inventory statistics. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const INVENTORY_READ_PRICE_HISTORY_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving price history. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const INVENTORY_READ_LIST_TAG_PART_PROFILE_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving list tag. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

// Activity Log
export const ACTIVITY_LOG_READ_LIST_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving activity log list. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

// Stock recon
export const STOCK_RECON_STAT_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving stock recon stats. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const STOCK_RECON_LIST_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving stock recon list. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const STOCK_RECON_DOWNLOAD_DATA_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue while downloading stock recon. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const ACTIVITY_LOG_DOWNLOAD_DATA_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue while downloading activity log list. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const STOCK_RECON_SCHEDULE_READ_LIST_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving stock recon schedule list. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const STOCK_RECON_SCHEDULE_DOWNLOAD_DATA_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue while downloading stock recon schedule list. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const TRANSFORMATION_INFO_FAIL: SnackBarType = {
  message:
    'There is an issue retrieving transformation info. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};
// Analytics Log
export const ANALYTICS_READ_LIST_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving user analytics list. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const ANALYTICS_DOWNLOAD_PAGE_VIEW_DATA_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue while downloading page view list. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const ANALYTICS_DOWNLOAD_SEARCH_VIEW_DATA_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue while downloading search view list. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

// dashboard

export const DASHBOARD_READ_LIST_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving dashboard resource. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

// System Health
export const SYSTEM_HEALTH_READ_CPU_STAT_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving CPU stat. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const SYSTEM_HEALTH_READ_SYSTEM_STATS_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving system stats. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const SYSTEM_HEALTH_READ_SYSTEM_CONNECTIVITY_RATE_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving system connectivity rate. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const SYSTEM_HEALTH_READ_DATA_USAGE_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving data usage. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const SYSTEM_HEALTH_READ_LAST_CONNECTION_DATE_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving system. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};
export const SYSTEM_HEALTH_READ_SYSTEM_UPTIME_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving system uptime. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const SYSTEM_UPTIME_DOWNLOAD_DATA_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue while downloading system uptime. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const SYSTEM_HEALTH_DATA_CONNECTIVITY_RATE_DOWNLOAD_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue while downloading data connectivity rate. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const SYSTEM_HEALTH_DOWNLOAD_DATA_USAGE_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue while downloading data usage. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};
// system info
export const DATE_LATEST_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving date and time of latest data synchronisation. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

// version
export const VERSION_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving version list. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

// transformation
export const TRANSFORMATION_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving transformation list. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const READ_TRANSFORMATION_SPARES_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving transformation spares. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const READ_TRANSFORMATION_ACTIVITY_STATS_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving transformation activity stats. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const READ_TIME_SPENT_TRASFORMATION_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving time spent transformation list. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const TRANSFORMATION_PROFILE_DETAIL_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving transformation profile detail. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const TRANSFORMATION_SPARE_SUMMARY_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving transformation spare summary. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const TRANSFORMATION_LOCATION_SUMMARY_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving transformation location summary. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const TRANSFORMATION_DOWNLOAD_DATA_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue while downloading transformation list. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const TRANSFORMATION_TIME_SPENT_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving transformation time spent. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const TRANSFORMATION_TAG_USAGE_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue retrieving transformation tag usage. Please try again or contact support.',
  messageType: SnackBarMessageTypeEnum.Error,
};

export const TRANSFORMATION_PROFILE_DOWNLOAD_DATA_FAIL_NOTIFICATION: SnackBarType = {
  message:
    'There is an issue while downloading transformation profile. Please try again or contact support',
  messageType: SnackBarMessageTypeEnum.Error,
};

// warning select upcoming vessel
export const SELECTION_UPCOMING_VESSEL_NOTIFICATION: SnackBarType = {
  message: 'Transformation has not started yet, no data available.',
  messageType: SnackBarMessageTypeEnum.Warning,
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
};
