import actionUtils from 'utils/action';
import DateUtils from 'utils/date';
import { Vessel } from 'types/loginStore';
import moment from 'moment';
import { SecureStorageEnum } from 'enums/auth';
import some from 'lodash/some';
import { TimezoneEnum } from 'enums/timeZoneEnum';
import secureStorageUtils from '../../utils/secureStorage';
import { updateSnackBarAction } from './snackBarActions';
import activityLogService from '../../services/ActivityLog';
import { DispatchType, ThunkActionType } from '../../types/store';
import {
  ReadListActivityLogParams,
  ReadListActivityLogResponse,
  DownloadActivityLogParams,
  TransformationInfo,
  VesselsTransformation,
} from '../../types/activityLog';
import { ApiError } from '../../types/api';
import {
  ReadListActivityLogSuccessAction,
  ReadListActivityLogRequestAction,
  DownloadActivityLogFailAction,
  DownloadActivityLogSuccessAction,
  DownloadActivityLogRequestAction,
  ToggleQuantityAction,
  ReadTransformationInfoSuccessAction,
} from '../../types/activityLogStore';
import { ActivityLogActionTypeEnum } from '../../enums/actions';
import {
  ACTIVITY_LOG_READ_LIST_FAIL_NOTIFICATION,
  ACTIVITY_LOG_DOWNLOAD_DATA_FAIL_NOTIFICATION,
  TRANSFORMATION_INFO_FAIL,
} from '../../utils/defaultValues/snackBar';
import { ReadListActivityLogParamsTypeEnum } from '../../enums/activityLog';
import { asyncTaskStartAction, asyncTaskStopAction } from './asyncTaskActions';
import { logoutAction } from './loginActions';

export const toggleDisplayQuantityAction = (
  value: boolean,
): ToggleQuantityAction => ({
  type: ActivityLogActionTypeEnum.TOGGLE_DISPLAY_QUANTITY,
  payload: value,
});

export const readListActivityLogRequestAction = (
  args?: Partial<ReadListActivityLogParams>,
): ReadListActivityLogRequestAction => ({
  type: ActivityLogActionTypeEnum.READ_LIST_REQUEST,
  payload: args,
});

export const readListActivityLogSuccessAction = (
  payload: ReadListActivityLogResponse,
  params?: Partial<ReadListActivityLogParams>,
): ReadListActivityLogSuccessAction => ({
  type: ActivityLogActionTypeEnum.READ_LIST_SUCCESS,
  payload,
  meta: params,
});

export const readListActivityLogFailAction = (payload: ApiError) => ({
  type: ActivityLogActionTypeEnum.READ_LIST_FAIL,
  payload,
});

export function readListActivityLogAction(
  args?: Partial<ReadListActivityLogParams>,
): ThunkActionType {
  return async (dispatch: DispatchType, getState): Promise<void> => {
    try {
      dispatch(readListActivityLogRequestAction(args));

      // Get current filter options from state
      const { activityLogReducer } = getState();
      const {
        filter,
        recordsPerpage,
        startTime,
        endTime,
        spareName,
        sparePartNumber,
        reason,
        tagLabel,
        includeStat,
        sort,
        sortField,
        reasonFields,
      } = activityLogReducer;

      // Using default params from current state if omit
      const params = {
        pageNumber: args?.pageNumber || 1,
        recordsPerpage: args?.recordsPerpage || recordsPerpage,
        filter: args?.filter || filter,
        startTime: args?.startTime || startTime,
        endTime: args?.endTime || endTime,
        includeStat: args?.includeStat || includeStat,
        sort: args?.sort || sort,
        sortField: args?.sortField || sortField,
        spareName: args?.spareName || spareName,
        sparePartNumber: args?.sparePartNumber || sparePartNumber,
        reason: args?.reason || reason,
        tagLabel: (args?.tagLabel || tagLabel)?.replace(/\s/g, ''),
        timeZone: TimezoneEnum.UTC,
        reasonFields: args?.reasonFields || reasonFields,
      };
      if (params.startTime) {
        params.startTime = DateUtils.formatHourToUTCStartOfDayWithISOStringFormat(
          params.startTime,
        );
      }
      if (params.endTime) {
        params.endTime = DateUtils.formatHourToUTCEndOfDayWithISOStringFormat(
          params.endTime,
        );
      }

      // No filter specified means all selected
      if (!params.filter || !params.filter.length) {
        params.filter = Object.values(ReadListActivityLogParamsTypeEnum);
      }

      const payload = await activityLogService.readListActivityLog(params);
      dispatch(readListActivityLogSuccessAction(payload, args));
    } catch (error) {
      dispatch(readListActivityLogFailAction(error));
      dispatch(
        updateSnackBarAction(ACTIVITY_LOG_READ_LIST_FAIL_NOTIFICATION, error),
      );
    }
  };
}

// Download
export const downloadDataRequestAction = (
  params?: Partial<DownloadActivityLogParams>,
): DownloadActivityLogRequestAction => ({
  type: ActivityLogActionTypeEnum.DOWNLOAD_DATA_REQUEST,
  payload: params,
});

export const downloadDataSuccessAction = (
  params?: Partial<DownloadActivityLogParams>,
): DownloadActivityLogSuccessAction => ({
  type: ActivityLogActionTypeEnum.DOWNLOAD_DATA_SUCCESS,
  meta: params,
});

export const downloadDataFailAction = (
  payload: ApiError,
): DownloadActivityLogFailAction => ({
  type: ActivityLogActionTypeEnum.DOWNLOAD_DATA_FAIL,
  payload,
});

export function downloadDataAction(
  args?: DownloadActivityLogParams,
): ThunkActionType {
  return async (dispatch: DispatchType, getState): Promise<void> => {
    try {
      dispatch(downloadDataRequestAction(args));

      // Get current filter options from state
      const { activityLogReducer } = getState();
      const {
        filter,
        startTime,
        endTime,
        includeStat,
        spareName,
        sparePartNumber,
        reason,
        tagLabel,
        sort,
        sortField,
        reasonFields,
      } = activityLogReducer;

      // Using default params from current state if omit
      const params = {
        filter: args?.filter || filter,
        startTime: args?.startTime || startTime,
        endTime: args?.endTime || endTime,
        includeStat: args?.includeStat || includeStat,
        exportFields: args?.exportFields || [],
        timeZone: TimezoneEnum.UTC,
        sort: args?.sort || sort,
        sortField: args?.sortField || sortField,
        spareName: args?.spareName || spareName,
        sparePartNumber: args?.sparePartNumber || sparePartNumber,
        reason: args?.reason || reason,
        tagLabel: (args?.tagLabel || tagLabel)?.replace(/\s/g, ''),
        reasonFields: args?.reasonFields || reasonFields,
      };
      params.startTime = DateUtils.formatHourToUTCStartOfDayWithISOStringFormat(
        params.startTime,
      );

      params.endTime = DateUtils.formatHourToUTCEndOfDayWithISOStringFormat(
        params.endTime,
      );
      // No filter specified means all selected
      if (!params.filter || !params.filter.length) {
        params.filter = Object.values(ReadListActivityLogParamsTypeEnum);
      }
      await activityLogService.downloadData(params);
      dispatch(downloadDataSuccessAction(args));
      const snackBarPayload = actionUtils.createSnackBarDownloadDataAction();
      dispatch(updateSnackBarAction(snackBarPayload));
    } catch (error) {
      dispatch(downloadDataFailAction(error));
      dispatch(
        updateSnackBarAction(
          ACTIVITY_LOG_DOWNLOAD_DATA_FAIL_NOTIFICATION,
          error,
        ),
      );
    }
  };
}

export const readTransformationInfoSuccessAction = (
  vesselsTransformation: VesselsTransformation,
  currentVesselTransformation?: TransformationInfo,
): ReadTransformationInfoSuccessAction => ({
  type: ActivityLogActionTypeEnum.READ_TRANSFORMAION_SUCCESS,
  vesselsTransformation,
  currentVesselTransformation,
});

export function readTransformationInfoAction(
  vessels: Vessel[],
): ThunkActionType {
  const taskId = ActivityLogActionTypeEnum.READ_TRANSFORMAION_SUCCESS;
  return async (dispatch: DispatchType): Promise<void> => {
    try {
      const mockResponse = [
        {
          key: '1234567',
          value: {
            startDate: '2022-07-27T00:00:00.000Z',
          },
        },
        {
          key: '1122337',
          value: {
            startDate: '2022-07-06T17:00:00.000Z',
          },
        },
        {
          key: '2608991',
          value: {},
        },
        {
          key: '2202142',
          value: {
            startDate: '2022-07-28T00:00:00.000Z',
          },
        },
        {
          key: '9273337',
          value: {},
        },
        {
          key: '9302968',
          value: {},
        },
        {
          key: '8754213',
          value: {},
        },
        {
          key: '5552299',
          value: {
            startDate: '2022-02-15T17:00:00.000Z',
          },
        },
        {
          key: '9386964',
          value: {
            startDate: '2021-01-28T17:00:00.000Z',
            endDate: '2022-08-19T03:04:22.333Z',
          },
        },
        {
          key: '4651254',
          value: {},
        },
        {
          key: '7586492',
          value: {},
        },
        {
          key: '9795048',
          value: {
            startDate: '2022-05-02T17:00:00.000Z',
          },
        },
        {
          key: '9795065',
          value: {
            startDate: '2022-06-09T17:00:00.000Z',
          },
        },
        {
          key: '9453999',
          value: {
            startDate: '2022-02-28T17:00:00.000Z',
          },
        },
        {
          key: '9795006',
          value: {},
        },
        {
          key: '9321483',
          value: {},
        },
        {
          key: '9133345',
          value: {},
        },
        {
          key: '5468884',
          value: {
            startDate: '2022-03-11T17:00:00.000Z',
          },
        },
        {
          key: '7988546',
          value: {},
        },
        {
          key: '9112002',
          value: {
            startDate: '2022-03-15T17:00:00.000Z',
          },
        },
        {
          key: '2233447',
          value: {},
        },
        {
          key: '0907810',
          value: {},
        },
        {
          key: '9078140',
          value: {},
        },
        {
          key: '1403221',
          value: {},
        },
        {
          key: '1345034',
          value: {
            startDate: '2022-03-17T17:00:00.000Z',
          },
        },
        {
          key: '9078031',
          value: {},
        },
        {
          key: '1403222',
          value: {},
        },
        {
          key: '9078143',
          value: {},
        },
        {
          key: '9078142',
          value: {},
        },
        {
          key: '1231238',
          value: {
            startDate: '2022-03-02T17:00:00.000Z',
          },
        },
        {
          key: '1231236',
          value: {
            startDate: '2022-08-07T00:00:00.000Z',
          },
        },
        {
          key: '1345033',
          value: {
            startDate: '2022-03-18T17:00:00.000Z',
          },
        },
        {
          key: '1231237',
          value: {},
        },
        {
          key: '1345032',
          value: {
            startDate: '2022-03-20T17:00:00.000Z',
          },
        },
        {
          key: '1345031',
          value: {
            startDate: '2022-03-17T17:00:00.000Z',
          },
        },
        {
          key: '9087016',
          value: {},
        },
        {
          key: '2112302',
          value: {
            startDate: '2021-12-29T17:00:00.000Z',
          },
        },
        {
          key: '2112303',
          value: {},
        },
        {
          key: '2112304',
          value: {
            startDate: '2021-12-31T17:00:00.000Z',
          },
        },
        {
          key: '9014032',
          value: {
            startDate: '2022-06-25T17:00:00.000Z',
          },
        },
        {
          key: '2112301',
          value: {
            startDate: '2021-12-31T17:00:00.000Z',
          },
        },
        {
          key: '2112306',
          value: {
            startDate: '2022-01-02T17:00:00.000Z',
          },
        },
        {
          key: '1231123',
          value: {
            startDate: '2021-12-29T17:00:00.000Z',
          },
        },
        {
          key: '1231124',
          value: {},
        },
        {
          key: '9078141',
          value: {},
        },
        {
          key: '1232346',
          value: {},
        },
        {
          key: '1793001',
          value: {
            startDate: '2022-07-20T00:00:00.000Z',
            endDate: '2022-08-08T07:36:09.836Z',
          },
        },
        {
          key: '2210121',
          value: {
            startDate: '2022-10-11T17:00:00.000Z',
          },
        },
        {
          key: '1793002',
          value: {},
        },
        {
          key: '9112233',
          value: {},
        },
        {
          key: '7456124',
          value: {},
        },
        {
          key: '2341156',
          value: {},
        },
        {
          key: '2133456',
          value: {},
        },
        {
          key: '9874564',
          value: {},
        },
        {
          key: '9078456',
          value: {},
        },
        {
          key: '7845634',
          value: {
            startDate: '2021-12-05T17:00:00.000Z',
          },
        },
        {
          key: '9931134',
          value: {
            startDate: '2022-12-11T17:00:00.000Z',
          },
        },
        {
          key: '9714276',
          value: {},
        },
        {
          key: '5555555',
          value: {},
        },
        {
          key: '8765999',
          value: {},
        },
        {
          key: '9426207',
          value: {
            startDate: '2021-08-26T00:00:00.000Z',
            endDate: '2022-07-01T08:13:04.544Z',
          },
        },
        {
          key: '9781455',
          value: {},
        },
        {
          key: '7456898',
          value: {},
        },
        {
          key: '5468887',
          value: {},
        },
        {
          key: '9047564',
          value: {},
        },
        {
          key: '4780154',
          value: {},
        },
        {
          key: '1221665',
          value: {},
        },
        {
          key: '9321665',
          value: {},
        },
        {
          key: '1321665',
          value: {},
        },
        {
          key: '9329679',
          value: {},
        },
        {
          key: '5566331',
          value: {},
        },
        {
          key: '9756389',
          value: {
            startDate: '2021-06-01T17:00:00.000Z',
            endDate: '2021-12-09T07:36:09.836Z',
          },
        },
        {
          key: '9064085',
          value: {},
        },
        {
          key: '9078017',
          value: {},
        },
        {
          key: '8765998',
          value: {},
        },
        {
          key: '1398765',
          value: {},
        },
        {
          key: '1234567',
          value: {},
        },
        {
          key: '2202141',
          value: {},
        },
        {
          key: '2202143',
          value: {},
        },
        {
          key: '2002142',
          value: {},
        },
        {
          key: '3453222',
          value: {},
        },
        {
          key: '5454672',
          value: {},
        },
        {
          key: '2323222',
          value: {},
        },
        {
          key: '7890435',
          value: {},
        },
        {
          key: '9808790',
          value: {},
        },
        {
          key: '1231231',
          value: {},
        },
        {
          key: '1231232',
          value: {},
        },
        {
          key: '9129129',
          value: {},
        },
        {
          key: '1233333',
          value: {},
        },
        {
          key: '9111111',
          value: {},
        },
        {
          key: '1005001',
          value: {},
        },
        {
          key: '4567112',
          value: {},
        },
        {
          key: '9714216',
          value: {},
        },
        {
          key: '1234568',
          value: {
            startDate: '2022-01-09T16:00:00.000Z',
          },
        },
        {
          key: '0000011',
          value: {},
        },
        {
          key: '9898989',
          value: {
            startDate: '2022-06-27T17:00:00.000Z',
          },
        },
        {
          key: '8794451',
          value: {},
        },
        {
          key: '8541222',
          value: {},
        },
        {
          key: '2000026',
          value: {},
        },
        {
          key: '2000001',
          value: {},
        },
        {
          key: '0101100',
          value: {
            startDate: '2022-08-21T00:00:00.000Z',
          },
        },
        {
          key: '1111112',
          value: {},
        },
        {
          key: '1111111',
          value: {},
        },
        {
          key: '9021345',
          value: {},
        },
        {
          key: '5555551',
          value: {},
        },
        {
          key: '6452310',
          value: {},
        },
        {
          key: '9021374',
          value: {},
        },
        {
          key: '4567890',
          value: {},
        },
        {
          key: '6565656',
          value: {},
        },
        {
          key: '9012349',
          value: {},
        },
        {
          key: '9095062',
          value: {},
        },
        {
          key: '4832161',
          value: {},
        },
      ];
      // Dispatch processing
      dispatch(asyncTaskStartAction(taskId));
      const transformed: TransformationInfo[] = [];
      const inProgress: TransformationInfo[] = [];
      const upcoming: TransformationInfo[] = [];
      const vesselsTransformation = new Map<string, any>();
      vessels.forEach((item) => vesselsTransformation.set(item.id, item));
      // const imoNumbers = Array.from(vesselsTransformation.keys());
      // // eslint-disable-next-line @typescript-eslint/no-unused-vars
      // const payload = await activityLogService.readTransformationByVessel(
      //   imoNumbers,
      // );
      mockResponse.forEach((item) =>
        vesselsTransformation.set(item.key, {
          ...vesselsTransformation.get(item.key),
          startDate: item.value.startDate,
          endDate: item.value.endDate,
        }),
      );

      // Filter vessel transformed, inProgress and upcoming
      Array.from(vesselsTransformation.values()).forEach((value) => {
        const { startDate, endDate } = value;
        if (endDate && moment(endDate).isBefore(new Date())) {
          transformed.push(value);
          return;
        }
        if (startDate) {
          if (moment(startDate).isAfter(new Date())) {
            upcoming.push(value);
          } else inProgress.push(value);
        } else {
          upcoming.push(value);
        }
      });
      const selectedVesselsLogin = [...transformed, ...inProgress, ...upcoming];

      const currentSelectedVessel = secureStorageUtils.getItem(
        SecureStorageEnum.VESSEL_ID,
      );
      // Save vessel_id if needed
      if (selectedVesselsLogin && selectedVesselsLogin.length) {
        if (
          !currentSelectedVessel ||
          upcoming.findIndex((val) => val.id === currentSelectedVessel) >= 0 ||
          selectedVesselsLogin.findIndex(
            (val) => val.id === currentSelectedVessel,
          ) === -1
        ) {
          secureStorageUtils.setItem(
            SecureStorageEnum.VESSEL_ID,
            selectedVesselsLogin[0].id as string,
          );
        }
      } else {
        // Delete the vessel_id in storage because it's invalid!
        secureStorageUtils.setItem(SecureStorageEnum.VESSEL_ID, '');
        dispatch(logoutAction());
        return;
      }
      const currentVesselId = actionUtils.getVesselId();
      const isTransformedVessel = some(transformed, { id: currentVesselId });
      let currentVesselTransformation = vesselsTransformation.get(
        currentVesselId,
      );
      currentVesselTransformation = {
        ...currentVesselTransformation,
        isTransformedVessel,
      };
      // return data to reducer
      dispatch(
        readTransformationInfoSuccessAction(
          {
            transformed,
            inProgress,
            upcoming,
          },
          currentVesselTransformation,
        ),
      );
      // Dispatch done
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error));
      dispatch(updateSnackBarAction(TRANSFORMATION_INFO_FAIL, error));
    }
  };
}
