export enum StockReconDocTypeEnum {
  GROUP = 'group',
  SPARE = 'spare',
}

export enum StockReconStatusEnum {
  IN_PROGRESS = 'inprogress',
  COMPLETE = 'complete',
}

export enum StockReconFieldEnum {
  Name = 'name',
  Status = 'status',
  Level = 'level',
  ParentName = 'parentName',
}

export enum StockTakeHistoryFieldEnum {
  LOG = 'log',
  LOCATION = 'location',
  SPARE_GROUP = 'spare/group',
}

export enum StockReconStatTypeEnum {
  TOTAL_TIME_SPENT = 'total-time-spent',
  AVERAGE_RECON_PER_DAY = 'average-recon-per-day',
  TOTAL_EQUIPMENTS_TO_RECON = 'total-equipments-to-recon',
  TOTAL_EQUIPMENTS_IN_PROGRESS = 'total-equipments-in-progress',
  TOTAL_EQUIPMENTS_FINISHED = 'total-equipments-finished',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}

export enum StockReconFilterFieldTypeEnum {
  COUNT = 'Equipment Count',
  DURATION = 'Duration (min)',
}

export enum StockReconExportLabelEnum {
  Name = 'System/Equipment Name',
  Status = 'Status',
  Level = 'System/Equipment Level',
  ParentName = 'Parent System/Equipment',
}
