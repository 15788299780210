import { ActivityLogFieldEnum } from 'enums/activityLog';
import { SortEnum } from 'enums/sortEnum';
import dateUtils from 'utils/date';

export const ActivityLogDefaultParams = {
  filter: [],
  readSource: [],
  startTime: dateUtils.getXDayFromDate(30).toISOString(),
  endTime: dateUtils.getCurrentUTCEndDateWithISOStringFormat(),
  includeStat: true,
  showQuantity: false,
  sort: SortEnum.DESCENDING,
  sortField: ActivityLogFieldEnum.TIMESTAMP,
};

export const ACTIVITY_LOG_NOTES_FILTER_REASON_FIELDS = [
  'workOrderDetail.name',
  'workOrderDetail.referenceId',
  'purchaseOrderDetail.referenceNumber',
  'poReason',
];
