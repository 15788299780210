import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CheckCircleOutlineRounded from '@material-ui/icons/CheckCircleOutlineOutlined';

import { resetSnackBarAction } from '../../store/actions/snackBarActions';
import { RootStateType } from '../../types/store';
import { styles } from './styles';

/**
 * SnackBar component
 * Render notification messages
 */
export default function SnackBar() {
  const classes = styles();
  const dispatch = useDispatch();
  const {
    show,
    message,
    messageType,
    anchorOrigin,
    useCustomSuccessIcon,
  } = useSelector((state: RootStateType) => state.snackBarReducer);

  const handleClose = () => {
    dispatch(resetSnackBarAction());
  };

  const successIcon = useMemo(() => {
    if (useCustomSuccessIcon) {
      return <CheckCircleOutlineRounded />;
    }
    return undefined;
  }, [useCustomSuccessIcon]);

  if (show) {
    const keyOfMessage = new Date().getTime();
    return (
      <div className={classes.root}>
        <Snackbar
          key={keyOfMessage}
          anchorOrigin={
            anchorOrigin ?? { vertical: 'top', horizontal: 'center' }
          }
          open={show}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            data-testid="alertSnackbar"
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity={messageType}
            iconMapping={{
              warning: <></>,
              success: successIcon,
            }}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
  return null;
}
