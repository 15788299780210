import {
  GetROBMovementParams,
  GetTagUsedParams,
  GetTagStatParams,
} from 'types/dashboard';
import dateUtils from 'utils/date';
import { CalendarUnitEnum } from 'enums/dashboard';
import { TimezoneEnum } from 'enums/timeZoneEnum';
import { PREVIOUS_YEARS_LIMIT } from './date';

export const defaultROBMovementParams: GetROBMovementParams = {
  startDate: dateUtils.getXDayFromDate(30).toISOString(),
  endDate: dateUtils.getCurrentUTCEndDateWithISOStringFormat(),
  imoNumber: undefined,
};

export const defatulTagUsedParams: GetTagUsedParams = {
  fromDate: dateUtils.getXDayFromDate(30).toISOString(),
  toDate: dateUtils.getCurrentUTCEndDateWithISOStringFormat(),
  dateInterval: CalendarUnitEnum.Day,
  timeZone: TimezoneEnum.UTC,
};

export const defaultTagStatParams: GetTagStatParams = {
  fromDate: dateUtils
    .getFirstDayOfPastNumberYear(PREVIOUS_YEARS_LIMIT)
    .toISOString(),
  toDate: dateUtils.getCurrentUTCEndDateWithISOStringFormat(),
};
