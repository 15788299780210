import { PREVIOUS_YEARS_LIMIT } from 'utils/defaultValues/date';
import dateUtils from 'utils/date';
import { SortEnum } from '../../enums/sortEnum';
import {
  PurchaseOrderFieldEnum,
  LineItemTagStatusStrickenOutEnum,
  ReadListPurchaseOrderParamsTypeEnum,
} from '../../enums/purchaseOrder';

export const ReadListPurchaseOrderParamsDefault = {
  types: [],
  sort: SortEnum.DESCENDING,
  sortField: PurchaseOrderFieldEnum.REFERENCE_NUMBER,
  startOrderDate: dateUtils
    .getFirstDayOfPastNumberYear(PREVIOUS_YEARS_LIMIT)
    .toISOString(),
  endOrderDate: dateUtils.getCurrentUTCEndDateWithISOStringFormat(),
};

// return tag status stricken out in line item
export const TagStatusStrickenOut = new Set(
  Object.values(LineItemTagStatusStrickenOutEnum),
);

export const POPendingDeliveryStatuses = [
  ReadListPurchaseOrderParamsTypeEnum.Pristine,
  ReadListPurchaseOrderParamsTypeEnum.PartialTagged,
  ReadListPurchaseOrderParamsTypeEnum.Tagged,
];
