import { AxiosError } from 'axios';
import { SnackBarMessageTypeEnum } from 'enums/SnackBarEnum';
import Auth from '../../services/Auth';
import { updateSnackBarAction } from './snackBarActions';
import secureStorageUtils from '../../utils/secureStorage';
import { AuthErrorCodeEnum, SecureStorageEnum } from '../../enums/auth';
import {
  AuthResponse,
  UserCredentials,
  ValidateResetTokenActionParams,
} from '../../types/user';
import { LoginActionTypeEnum } from '../../enums/actions';
import {
  AUTHORIZATION_ERROR_NOTIFICATION,
  AUTHORIZATION_NO_LOGIN_PERMISSION_ERROR_NOTIFICATION,
  AUTHORIZATION_SERVICE_UNREACHABLE_NOTIFICATION,
  PROFILE_NOT_FOUND,
  VESSSELS_NOT_FOUND,
  VALIDATE_RESET_PASSWORD_ERROR_NOTIFICATION,
} from '../../utils/defaultValues/snackBar';
import { Vessel } from '../../types/loginStore';
import { DispatchType } from '../../types/store';
import { asyncTaskStartAction, asyncTaskStopAction } from './asyncTaskActions';

export const switchToVessel = (vesselId: string) => async (
  dispatch: DispatchType,
) => {
  const currentVesselId = secureStorageUtils.getItem(
    SecureStorageEnum.VESSEL_ID,
  );
  if (vesselId && vesselId !== currentVesselId) {
    // Save the vesselId and reload the window
    secureStorageUtils.setItem(SecureStorageEnum.VESSEL_ID, vesselId);
    window.location.reload();
  }
};

export const loadUserProfile = () => async (dispatch: DispatchType) => {
  try {
    // Because of axios reponse interceptor the return type is no longer true
    const data: any = await Auth.getUser();
    const vessels: Array<Vessel> = data?.metaData?.vesselList;
    const currentSelectedVessel = secureStorageUtils.getItem(
      SecureStorageEnum.VESSEL_ID,
    );
    secureStorageUtils.setItem(
      SecureStorageEnum.USER_INFO,
      JSON.stringify(data),
    );
    // Save vessel_id if needed
    if (Array.isArray(vessels) && vessels.length) {
      // Save the default if it is not exist/not in the vessels
      if (
        !currentSelectedVessel ||
        vessels.findIndex((val) => val.id === currentSelectedVessel) === -1
      ) {
        secureStorageUtils.setItem(SecureStorageEnum.VESSEL_ID, vessels[0].id);
      }
    } else {
      // Delete the vessel_id in storage because it's invalid!
      secureStorageUtils.setItem(SecureStorageEnum.VESSEL_ID, '');
    }
    dispatch({
      type: LoginActionTypeEnum.SET_USER,
      payload: data,
    });
  } catch (error) {
    // Clear tokens and go back to login screen as the user is invalid!
    dispatch(logoutAction());
    // Show error
    dispatch(updateSnackBarAction(PROFILE_NOT_FOUND));
  }
};

export const loadUserVessels = () => async (dispatch: DispatchType) => {
  try {
    const vessels = await Auth.getVessels();
    dispatch({
      type: LoginActionTypeEnum.SET_VESSELS,
      payload: vessels,
    });
  } catch (error) {
    // Clear tokens and go back to login screen as the user is invalid!
    dispatch(logoutAction());
    // Show error
    dispatch(updateSnackBarAction(VESSSELS_NOT_FOUND));
  }
};

export const loginAction = (body: UserCredentials) => async (
  dispatch: DispatchType,
) => {
  try {
    dispatch({
      type: LoginActionTypeEnum.SET_LOADING,
      payload: true,
    });
    const { data, status } = await Auth.login(body);
    dispatch({
      type: LoginActionTypeEnum.SET_LOADING,
      payload: false,
    });
    let authResponse: AuthResponse = {
      data: { accessToken: '', refreshToken: '', expiresIn: 0 },
      status: 0,
    };
    if (status === 200 || status === 201) {
      authResponse = {
        data,
        status,
      };
      const { accessToken, refreshToken } = data;
      if (accessToken && refreshToken) {
        secureStorageUtils.setItem(SecureStorageEnum.ACCESS_TOKEN, accessToken);
        secureStorageUtils.setItem(
          SecureStorageEnum.REFRESH_TOKEN,
          refreshToken,
        );
        dispatch({
          type: LoginActionTypeEnum.SET_LOGIN,
          payload: { accessToken },
        });
      }
    } else {
      dispatch(
        updateSnackBarAction(AUTHORIZATION_SERVICE_UNREACHABLE_NOTIFICATION),
      );
    }
    return authResponse;
  } catch (error) {
    if (error.isAxiosError) {
      const axiosError: AxiosError = error;
      if (axiosError.response) {
        const { data, status } = axiosError.response;
        if (status === 401) {
          if (data.authCode === AuthErrorCodeEnum.NoLoginPermission) {
            dispatch(
              updateSnackBarAction(
                AUTHORIZATION_NO_LOGIN_PERMISSION_ERROR_NOTIFICATION,
              ),
            );
          } else {
            dispatch(updateSnackBarAction(AUTHORIZATION_ERROR_NOTIFICATION));
          }
        } else {
          dispatch(
            updateSnackBarAction({
              message: data.message,
              messageType: SnackBarMessageTypeEnum.Error,
            }),
          );
        }
      } else {
        dispatch(
          updateSnackBarAction(AUTHORIZATION_SERVICE_UNREACHABLE_NOTIFICATION),
        );
      }
    }
    dispatch({ type: LoginActionTypeEnum.SET_LOADING, payload: false });
    throw error;
  }
};

export const logoutAction = () => (dispatch: DispatchType) => {
  secureStorageUtils.removeItem(SecureStorageEnum.ACCESS_TOKEN);
  secureStorageUtils.removeItem(SecureStorageEnum.REFRESH_TOKEN);
  secureStorageUtils.removeItem(SecureStorageEnum.VESSEL_ID);
  secureStorageUtils.removeItem(SecureStorageEnum.USER_INFO);

  dispatch({ type: LoginActionTypeEnum.LOGOUT });
};

export function validateResetTokenAction(
  payload: ValidateResetTokenActionParams,
) {
  const taskId = `${LoginActionTypeEnum.VALIDATE_RESET_TOKEN}`;
  return async (dispatch: DispatchType): Promise<void> => {
    try {
      const { params, callback } = payload;
      dispatch(asyncTaskStartAction(taskId));
      const response = await Auth.validateResetToken(params);
      callback(response?.isValid);
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error));
      dispatch(
        updateSnackBarAction(VALIDATE_RESET_PASSWORD_ERROR_NOTIFICATION, error),
      );
    }
  };
}
