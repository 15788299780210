import { ResponseTypeEnum } from 'enums/exportDataEnum';
import AXIOS from './axios';
import {
  ReadListActivityLogResponse,
  ReadListActivityLogParams,
  DownloadActivityLogParams,
  TransformationInfoResponse,
} from '../types/activityLog';
import { apiRoutesEnum } from '../enums/routes';

async function readListActivityLog(
  params: ReadListActivityLogParams,
): Promise<ReadListActivityLogResponse> {
  // Change api to POST since we got a problem with array param (filter) in azure
  return AXIOS.post(apiRoutesEnum.ACTIVITY_LOGS, params);
}

async function downloadData(params: DownloadActivityLogParams): Promise<void> {
  return AXIOS.post(apiRoutesEnum.ACTIVITY_LOG_EXPORT_DATA, params, {
    responseType: ResponseTypeEnum.Text,
  });
}

async function readTransformationByVessel(
  imoNumbers: Array<any>,
): Promise<TransformationInfoResponse> {
  return AXIOS.post(apiRoutesEnum.TRANSFORMATION_INFO, {
    imoNumbers,
  });
}

export default {
  readListActivityLog,
  downloadData,
  readTransformationByVessel,
};
