import { Map } from 'immutable';
import { PurchaseOrderActionTypeEnum } from '../../enums/actions';
import {
  PurchaseOrderActionTypes,
  PurchaseOrderReducerState,
} from '../../types/purchaseOrderStore';
import { PURCHASE_ORDER_LIST_DATA_TABLE_OPTIONS } from '../../utils/defaultValues/dataTable';
import { ReadListPurchaseOrderParamsDefault } from '../../utils/defaultValues/purchaseOrder';

export const initialState: PurchaseOrderReducerState = {
  purchaseOrderList: undefined,
  purchaseOrderStat: undefined,
  POBySpare: Map(),
  recordsPerpage: PURCHASE_ORDER_LIST_DATA_TABLE_OPTIONS.rowsPerPage,
  statuses: ReadListPurchaseOrderParamsDefault.types,
  sort: ReadListPurchaseOrderParamsDefault.sort,
  sortField: ReadListPurchaseOrderParamsDefault.sortField,
  criteria: undefined,
  downloading: false,
  startOrderDate: ReadListPurchaseOrderParamsDefault.startOrderDate,
  endOrderDate: ReadListPurchaseOrderParamsDefault.endOrderDate,
  isMarkedAsDelivered: undefined,
};

export const purchaseOrderReducer = (
  state = initialState,
  action: PurchaseOrderActionTypes,
): PurchaseOrderReducerState => {
  switch (action.type) {
    case PurchaseOrderActionTypeEnum.READ_LIST_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case PurchaseOrderActionTypeEnum.READ_LIST_SUCCESS:
      return {
        ...state,
        purchaseOrderList: action.payload,
      };
    case PurchaseOrderActionTypeEnum.DOWNLOAD_DATA_REQUEST:
      return {
        ...state,
        downloading: true,
      };
    case PurchaseOrderActionTypeEnum.DOWNLOAD_DATA_SUCCESS:
    case PurchaseOrderActionTypeEnum.DOWNLOAD_DATA_FAIL:
      return {
        ...state,
        downloading: false,
      };
    case PurchaseOrderActionTypeEnum.READ_STAT_SUCCESS:
      return {
        ...state,
        purchaseOrderStat: action.payload,
      };
    case PurchaseOrderActionTypeEnum.READ_PO_BY_REFERENCE_ID:
      return {
        ...state,
        POBySpare: state.POBySpare.set(action.meta.referenceId, action.payload),
      };
    default:
      return state;
  }
};
