/**
 * actions.ts
 * List of Redux action types
 */

export enum LoginActionTypeEnum {
  SET_USER = 'login/set_user',
  SET_VESSELS = 'login/set_vessels',
  SET_LOGIN = 'login/set_login',
  LOGOUT = 'login/logout',
  SET_LOADING = 'login/set_loading',
  VALIDATE_RESET_TOKEN = 'login/validate_reset_token',
}

export enum SnackBarActionTypeEnum {
  UPDATE = 'SNACK_BAR_UPDATE',
  RESET = 'SNACK_BAR_RESET',
}

export enum AsyncTaskTypeEnum {
  ASYNC_TASK_START = 'ASYNC_TASK_START',
  ASYNC_TASK_STOP = 'ASYNC_TASK_STOP',
}

export enum PurchaseOrderActionTypeEnum {
  READ_REQUEST = 'PURCHASE_ORDER_READ_REQUEST',
  READ_SUCCESS = 'PURCHASE_ORDER_READ_SUCCESS',
  READ_FAIL = 'PURCHASE_ORDER_READ_FAIL',
  DOWNLOAD_DATA_REQUEST = 'PURCHASE_ORDER_DOWNLOAD_DATA_REQUEST',
  DOWNLOAD_DATA_SUCCESS = 'PURCHASE_ORDER_DOWNLOAD_DATA_SUCCESS',
  DOWNLOAD_DATA_FAIL = 'PURCHASE_ORDER_DOWNLOAD_DATA_FAIL',
  READ_LIST_REQUEST = 'PURCHASE_ORDER_READ_LIST_REQUEST',
  READ_LIST_SUCCESS = 'PURCHASE_ORDER_READ_LIST_SUCCESS',
  READ_LIST_FAIL = 'PURCHASE_ORDER_READ_LIST_FAIL',
  READ_STAT_SUCCESS = 'PURCHASE_ORDER_READ_STAT_SUCCESS',
  READ_PO_BY_REFERENCE_ID = 'READ_PO_BY_REFERENCE_ID',
}

export enum InventoryActionTypeEnum {
  READ_LIST_REQUEST = 'INVENTORY_READ_LIST_REQUEST',
  READ_LIST_SUCCESS = 'INVENTORY_READ_LIST_SUCCESS',
  READ_LIST_FAIL = 'INVENTORY_READ_LIST_FAIL',
  DOWNLOAD_DATA_REQUEST = 'INVENTORY_DOWNLOAD_DATA_REQUEST',
  DOWNLOAD_DATA_SUCCESS = 'INVENTORY_DOWNLOAD_DATA_SUCCESS',
  DOWNLOAD_DATA_FAIL = 'INVENTORY_DOWNLOAD_DATA_FAIL',
  READ_LIST_TAG_REQUEST = 'INVENTORY_READ_LIST_TAG_REQUEST',
  READ_LIST_TAG_SUCCESS = 'INVENTORY_READ_LIST_TAG_SUCCESS',
  READ_LIST_TAG_FAIL = 'INVENTORY_READ_LIST_TAG_FAIL',
  READ_PRODUCT_DETAIL_SUCCESS = 'READ_PRODUCT_DETAIL_SUCCESS',
  READ_PART_PROFILES_SUCCESS = 'READ_PART_PROFILES_SUCCESS',
  READ_PART_PROFILES_REQUEST = 'READ_PART_PROFILES_REQUEST',
  READ_PRODUCT_SUP_EQM_SUCCESS = 'READ_PRODUCT_SUP_EQM_SUCCESS',
  READ_INVENTORY_STATISTICS_SUCCESS = 'READ_INVENTORY_STATISTICS_SUCCESS',
  READ_PRODUCT_HIERARCHY_SUCCESS = 'READ_PRODUCT_HIERARCHY_SUCCESS',
  READ_PRODUCT_VESSEL_DAYS_ON_BOARD_SUCCESS = 'READ_PRODUCT_VESSEL_DAYS_ON_BOARD_SUCCESS',
  READ_PRICE_HISTORY_SUCCESS = 'READ_PRICE_HISTORY_SUCCESS',
  READ_PRICE_HISTORY_REQUEST = 'READ_PRICE_HISTORY_REQUEST',
  READ_LIST_TAG_PART_PROFILE_REQUEST = 'READ_LIST_TAG_PART_PROFILE_REQUEST',
  READ_LIST_TAG_PART_PROFILE_SUCCESS = 'READ_LIST_TAG_PART_PROFILE_SUCCESS',
  READ_LIST_TAG_PART_PROFILE_FAIL = 'READ_LIST_TAG_PART_PROFILE_FAIL',
  CHANGE_DAYS_ON_BOARD_PARAMS = 'CHANGE_DAYS_ON_BOARD_PARAMS',
}

export enum StockReconScheduleActionTypeEnum {
  READ_LIST_REQUEST = 'STOCK_RECON_SCHEDULE_READ_LIST_REQUEST',
  READ_LIST_SUCCESS = 'STOCK_RECON_SCHEDULE_READ_LIST_SUCCESS',
  READ_LIST_FAIL = 'STOCK_RECON_SCHEDULE_READ_LIST_FAIL',
  DOWNLOAD_DATA_REQUEST = 'STOCK_RECON_SCHEDULE_DOWNLOAD_DATA_REQUEST',
  DOWNLOAD_DATA_SUCCESS = 'STOCK_RECON_SCHEDULE_DOWNLOAD_DATA_SUCCESS',
  DOWNLOAD_DATA_FAIL = 'STOCK_RECON_SCHEDULE_DOWNLOAD_DATA_FAIL',
  READ_LIST_VESSEL_REQUEST = 'STOCK_RECON_SCHEDULE_READ_LIST_VESSEL_REQUEST',
  READ_LIST_VESSEL_SUCCESS = 'STOCK_RECON_SCHEDULE_READ_LIST_VESSEL_SUCCESS',
  READ_LIST_VESSEL_FAIL = 'STOCK_RECON_SCHEDULE_READ_LIST_VESSEL_FAIL',
}

export enum ActivityLogActionTypeEnum {
  READ_LIST_REQUEST = 'ACTIVITY_LOG_READ_LIST_REQUEST',
  READ_LIST_SUCCESS = 'ACTIVITY_LOG_READ_LIST_SUCCESS',
  READ_LIST_FAIL = 'ACTIVITY_LOG_READ_LIST_FAIL',
  DOWNLOAD_DATA_REQUEST = 'ACTIVITY_LOG_DOWNLOAD_DATA_REQUEST',
  DOWNLOAD_DATA_SUCCESS = 'ACTIVITY_LOG_DOWNLOAD_DATA_SUCCESS',
  DOWNLOAD_DATA_FAIL = 'ACTIVITY_LOG_DOWNLOAD_DATA_FAIL',
  TOGGLE_DISPLAY_QUANTITY = 'ACTIVITY_LOG_TOGGLE_DISPLAY_QUANTITY',
  READ_TRANSFORMAION_SUCCESS = 'READ_TRANSFORMAION_SUCCESS',
}

export enum StockReconActionTypeEnum {
  READ_LIST_REQUEST = 'STOCK_RECON_READ_LIST_REQUEST',
  READ_LIST_SUCCESS = 'STOCK_RECON_READ_LIST_SUCCESS',
  READ_LIST_FAIL = 'STOCK_RECON_READ_LIST_FAIL',
  READ_LIST_STOCK_TAKE_HISTORY_REQUEST = 'STOCK_RECON_READ_LIST_STOCK_TAKE_HISTORY_REQUEST',
  READ_LIST_STOCK_TAKE_HISTORY_SUCCESS = 'STOCK_RECON_READ_LIST_STOCK_TAKE_HISTORY_SUCCESS',
  READ_LIST_STOCK_TAKE_HISTORY_FAIL = 'STOCK_RECON_READ_LIST_STOCK_TAKE_HISTORY_FAIL',
  CHANGE_ACTIVITY_TYPE = 'STOCK_RECON_CHANGE_ACTIVITY_TYPE',
  STOCK_RECON_DOWNLOAD_SUCCESS = 'STOCK_RECON_DOWNLOAD_SUCCESS',
  READ_ACTIVITIES_SUMMARY = 'STOCK_RECON_READ_ACTIVITIES_SUMMARY',
  READ_ACTIVITIES_PER_DAY = 'STOCK_RECON_READ_ACTIVITIES_PER_DAY',
  RESET = 'RESET_STATE_STOCK_RECON',
}

export enum UserAnalyticsActionTypeEnum {
  READ_REQUEST = 'USER_ANALYTICS_READ_REQUEST',
  READ_SUCCESS = 'USER_ANALYTICS_READ_SUCCESS',
  READ_FAIL = 'USER_ANALYTICS_READ_FAIL',
  DOWNLOAD_PAGE_VIEW_DATA_REQUEST = 'PAGE_VIEW_DOWNLOAD_DATA_REQUEST',
  DOWNLOAD_PAGE_VIEW_DATA_SUCCESS = 'PAGE_VIEW_DOWNLOAD_DATA_SUCCESS',
  DOWNLOAD_PAGE_VIEW_DATA_FAIL = 'PAGE_VIEW_DOWNLOAD_DATA_FAIL',
  DOWNLOAD_SEARCH_VIEW_DATA_REQUEST = 'SEARCH_VIEW_DOWNLOAD_DATA_REQUEST',
  DOWNLOAD_SEARCH_VIEW_DATA_SUCCESS = 'SEARCH_VIEW_DOWNLOAD_DATA_SUCCESS',
  DOWNLOAD_SEARCH_VIEW_DATA_FAIL = 'SEARCH_VIEW_DOWNLOAD_DATA_FAIL',
}

export enum SystemHealthActionTypeEnum {
  READ_CPU_STAT_REQUEST = 'SYSTEM_HEALTH_READ_CPU_STAT_REQUEST',
  READ_CPU_STAT_SUCCESS = 'SYSTEM_HEALTH_READ_CPU_STAT_SUCCESS',
  READ_SYSTEM_STATS_REQUEST = 'SYSTEM_HEALTH_READ_SYSTEM_STATS_REQUEST',
  READ_SYSTEM_STATS_SUCCESS = 'SYSTEM_HEALTH_READ_SYSTEM_STATS_SUCCESS',
  READ_LAST_CONNECTTION_DATE_SUCCESS = 'SYSTEM_HEALTH_READ_LAST_CONNECTTION_DATE_SUCCESS',
  READ_SYSTEM_CONNECTIVITY_RATE_REQUEST = 'READ_SYSTEM_CONNECTIVITY_RATE_REQUEST',
  READ_SYSTEM_CONNECTIVITY_RATE_SUCCESS = 'READ_SYSTEM_CONNECTIVITY_RATE_SUCCESS',
  READ_DATA_USAGE_REQUEST = 'SYSTEM_HEALTH_READ_DATA_USAGE_REQUEST',
  READ_DATA_USAGE_SUCCESS = 'SYSTEM_HEALTH_READ_DATA_USAGE_SUCCESS',
  READ_SYSTEM_UPTIME_REQUEST = 'READ_SYSTEM_UPTIME_REQUEST',
  READ_SYSTEM_UPTIME_SUCCESS = 'READ_SYSTEM_UPTIME_SUCCESS',
  DOWNLOAD_SYSTEM_UPTIME_SUCCESS = 'DOWNLOAD_SYSTEM_UPTIME_SUCCESS',
  DOWNLOAD_CONNECTIVITY_RATE_SUCCESS = 'DOWNLOAD_CONNECTIVITY_RATE_SUCCESS',
  DOWNLOAD_DATA_USAGE_SUCCESS = 'DOWNLOAD_DATA_USAGE_SUCCESS',
}

export enum DashboardActionTypeEnum {
  READ_CRITICAL_SPARE_REQUEST = 'READ_CRITICAL_SPARE_REQUEST',
  READ_CRITICAL_SPARE_SUCCESS = 'READ_CRITICAL_SPARE_SUCCESS',
  READ_CRITICAL_SPARE_FAIL = 'READ_CRITICAL_SPARE_FAIL',
  PD_REQUEST = 'PENDING_DELIVERY_REQUEST',
  PD_SUCCESS = 'PENDING_DELIVERY_SUCCESS',
  PD_FAIL = 'PENDING_DELIVERY_FAIL',
  SPARE_COUNT_BY_STATUS_SUCCESS = 'SPARE_COUNT_BY_STATUS_SUCCESS',
  READ_ROB_MOVEMENT_REQUEST = 'READ_ROB_MOVEMENT_REQUEST',
  READ_ROB_MOVEMENT_SUCCESS = 'READ_ROB_MOVEMENT_SUCCESS',
  TAGS_USED_REQUEST = 'TAGS_USED_REQUEST',
  TAGS_USED_SUCCESS = 'TAGS_USED_SUCCESS',
  TAG_STAT_REQUEST = 'TAG_STAT_REQUEST',
  TAG_ALL_TIME_SUCCESS = 'TAG_ALL_TIME_SUCCESS',
}

export enum SystemInfoActionTypeEnum {
  READ_LATEST_ACTIVITY_SUCCESS = 'READ_LATEST_ACTIVITY_SUCCESS',
}

export enum VersionActionTypeEnum {
  READ_VERSION_SUCCESS = 'READ_VERSION_SUCCESS',
}

export enum TransformationActionTypeEnum {
  READ_TRANSFORMATION_REQUEST = 'READ_TRANSFORMATION_REQUEST',
  READ_TRANSFORMATION_SUCCESS = 'READ_TRANSFORMATION_SUCCESS',
  DOWNLOAD_DATA_SUCCESS = 'TRANSFORMATION_DOWNLOAD_DATA_SUCCESS',
  READ_TIME_SPENT_SUCCESS = 'READ_TIME_SPENT_SUCCESS',
  DOWNLOAD_TRANSFORMATION_PROFILE_SUCCESS = 'DOWNLOAD_TRANSFORMATION_PROFILE_SUCCESS',
  UPDATE_SORT_FOR_SPARE = 'UPDATE_SORT_FOR_SPARE',
}
