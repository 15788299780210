import secureStorageUtils from 'utils/secureStorage';
import { SecureStorageEnum } from 'enums/auth';
import {
  SnackBarMessageEnum,
  SnackBarMessageTypeEnum,
} from 'enums/SnackBarEnum';
import { SnackBarType } from 'types/snackBar';

const getVesselId = () =>
  secureStorageUtils.getItem(SecureStorageEnum.VESSEL_ID) || '';

const createSnackBarDownloadDataAction = (): SnackBarType => {
  const userInfo = JSON.parse(
    secureStorageUtils.getItem(SecureStorageEnum.USER_INFO) || '{}',
  );
  const userName = userInfo.username || '';
  return {
    message: `${SnackBarMessageEnum.DownLoadDataSuccess} ${userName}`,
    messageType: SnackBarMessageTypeEnum.Success,
    useCustomSuccessIcon: true,
  };
};

const actionUtils = {
  getVesselId,
  createSnackBarDownloadDataAction,
};

export default actionUtils;
