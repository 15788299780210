import {
  SnackBarActionTypes,
  SnackBarReducerState,
} from '../../types/snackBarStore';
import { SnackBarActionTypeEnum } from '../../enums/actions';

export const initialState: SnackBarReducerState = {
  show: false,
  message: '',
  messageType: undefined,
};

export const snackBarReducer = (
  state = initialState,
  action: SnackBarActionTypes,
) => {
  const { type, payload } = action;
  switch (type) {
    case SnackBarActionTypeEnum.UPDATE: {
      return {
        ...state,
        ...payload,
        show: true,
        useCustomSuccessIcon: payload?.useCustomSuccessIcon,
      };
    }
    case SnackBarActionTypeEnum.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
