// Routes available for authenticated user
export enum appRoutesEnum {
  DASHBOARD = '/dashboard',
  ACTIVITY_LOG = '/activity-log',
  INVENTORY = '/inventory',
  STOCK_RECON_SCHEDULE = '/stock-recon',
  ACTIVITIY_LOG = '/activity-logs',
  TRANSFORMATION = '/transformation',
  USER_ANALYTICS = '/user-analytics',
  STOCK_RECON = '/stock-recon/stats',
  PURCHASE_ORDER = '/purchase-order',
  SYSTEM_HEALTH = '/system-health',
  HELP_CENTER = 'https://sparescnx.atlassian.net/servicedesk/customer/portal/1/topic/0d747d43-8399-48f2-b523-8a300ea1ecd1',
  PART_PROFILE = '/inventory/:docType/:partId',
  TRANSFORMATION_PROFILE = '/transformation/:imoNo',
  CONTACT_US = 'https://sparescnx.atlassian.net/servicedesk/customer/portal/1/group/1/create/1',
}

// Routes available for authenticated user
export enum authRoutesEnum {
  LOGIN = '/login',
  RESET_PASSWORD = '/reset-password',
  FORGOT_PASSWORD = '/forgot-password',
  SETUP_PASSWORD = '/setup-password',
  TERM_CONDITIONS = '/term-conditions.pdf',
  CONTACT_US = 'https://sparescnx.atlassian.net/servicedesk/customer/portal/1/group/1/create/1',
}

// API routes

export enum apiRoutesEnum {
  USER_PROFILE = '/user/profile',
  USER_VESSELS = '/vessel/get-vessel-list',
  PURCHASE_ORDERS = '/purchase-order/search',
  PO_PENDING_CHECKIN = '/purchase-order/search',
  PURCHASE_ORDER_EXPORT_DATA = '/purchase-order/export-data',
  PURCHASE_ORDER_GET_STAT = '/purchase-order/get-stat',
  ACTIVITY_LOGS = '/activity/search',
  INVENTORIES = '/products/get-spares',
  ACTIVITY_LOG_EXPORT_DATA = '/activity/export-data',
  INVENTORY_TAGS = '/tag/get-tags-by-spare-id',
  INVENTORY_STATISTICS = '/products/get-statistics',
  INVENTORY_ROB = '/products/get-rob-by-reference-id',
  STOCK_RECON_SCHEDULES = '/stock-take/get-schedule-list',
  STOCK_RECON_SCHEDULE_EXPORT_DATA = '/stock-take/export-schedule-list',
  LIST_VESSEL = '/vessel/get-vessel-list',
  PRODUCT_HIERARCHY = '/products/get-product-hierarchy',
  STOCK_RECON = '/stock-take/get-history-by-criterias',
  STOCK_TAKE = '/stock-take/history',
  STOCK_RECON_ACTIVITIES_PER_DAY = '/stock-take/get-activities-per-day',
  STOCK_RECON_ACTIVITIES_SUMMARY = '/stock-take/activity-summary',
  INVENTORY_SEARCH_MANUFACTURE = '/products/get-manufacturer-list',
  INVENTORY_SEARCH_LOCATION = '/tag/search-location',
  INVENTORY_EXPORT_DATA = '/products/export-data',
  USER_ANALYTICS_PAGE_VIEW = '/analytics/page-views-stat',
  USER_ANALYTICS_PAGE_VIEW_EXPORT_DATA = '/analytics/export-page-stat',
  USER_ANALYTICS_SEARCH_VIEW = '/analytics/search-keyword-stat',
  USER_ANALYTICS_SEARCH_VIEW_EXPORT_DATA = '/analytics/export-search-stat',
  USER_ANALYTICS_SESSISON = '/analytics/session-stat',
  USER_ANALYTICS_FIND_EVENT = '/analytics/find-stat',
  SYSTEM_HEALTH_UPTIME_STAT = '/system-health/get-system-uptime-stat',
  SYSTEM_HEALTH_ERP_IMPORT_STAT = '/system-health/get-erp-import-stat',
  SYSTEM_HEALTH_CONNECTIVITY_RATE_STAT = '/system-health/get-connectivity-rate-stat',
  SYSTEM_HEALTH_DATA_CONSUMPTION_STAT = '/system-health/get-data-consumption-stat',
  SYSTEM_HEALTH_CPU_UTILIZATION = '/system-health/get-cpu-stat',
  SYSTEM_HEALTH_LAST_CONNECTION_DATE = '/system-health/get-latest-vessel-connection',
  SYSTEM_HEALTH_CONNECTIVITY_RATE = '/system-health/get-connectivity-rate-group-by-date',
  SYSTEM_HEALTH_DATA_USAGE = '/system-health/get-data-consumption-group-by-date',
  SYSTEM_HEALTH_SYSTEM_UPTIME = '/system-health/get-system-uptime-group-by-date',
  SYSTEM_HEALTH_DOWNLOAD_SYSTEM_UPTIME = '/system-health/create-job-export-system-uptime-group-by-date',
  SYSTEM_HEALTH_DOWNLOAD_DATA_CONNECTIVITY_RATE = '/system-health/create-job-export-connectivity-rate-group-by-date',
  SYSTEM_HEALTH_DOWNLOAD_DATA_USAGE = '/system-health/create-job-export-data-consumption-group-by-date',
  DASHBOARD_LOW_CRITICAL_SPARES = '/products/get-critical-spares',
  PRODUCT_DETAIL = '/products/detail',
  PRODUCT_SUPPLIER_EQUIPMENTS = '/products/get-suppliers-equipments-by-reference-id',
  VESSEL_DAYS_ON_BOARD = '/products/get-vessel-days-onboard',
  GET_TAG_STAT = '/tag/get-tag-usage-by-status',
  GET_TAG_USED = '/tag/get-tag-usage-by-date-range',
  STOCK_RECON_EXPORT_DATA = '/stock-take/export-data',
  GET_PRICE_HISTORY = '/products/get-price-history-by-reference-id',
  GET_SPARES_COUNT_BY_STATUS = '/tag/get-spares-count-by-status',
  SYSTEM_INFO_LATEST_ACTIVITY = '/system-info/get-latest-data-timestamp',
  GET_PO_BY_REFERENCE_ID = 'purchase-order/get-by-reference-id',
  TRANSFORMATION_INFO = '/transformation/get-transformation-info',
  GET_VERSION = '/version/get-version-list',
  GET_TRANSFORMATION = '/transformation/get-transformation-schedule-list',
  TRANSFORMATION_EXPORT_DATA = '/transformation/export-schedules',
  TRANSFORMATION_PROFILE_DETAIL = '/transformation/get-transformation-profile-detail',
  TRANSFORMATION_TIME_SPENT = '/transformation/get-transformation-time-spent',
  TRANSFORMATION_SPARE_SUMMARY = '/transformation/get-transformation-spare-summary',
  TRANSFORMATION_SPARES = '/transformation/get-spares',
  TRANSFORMATION_ACTIVITY_STATS = '/transformation/get-activity-stats',
  TRANSFORMATION_LOCATION_SUMMARY = '/transformation/get-location-summary',
  TRANSFORMATION_TAG_USAGE = '/transformation/get-tag-usage',
  TRANSFORMATION_TIME_SPENT_SUMMARY = '/transformation/get-transformation-time-spent',
  TRANSFORMATION_PROFILE_DOWNLOAD_DATA = '/transformation/create-job-export-transformation-profile',
  GET_ROB_MOVEMENT = '/vessel-rob-snapshot',
}

// Oxygen routes
export enum oxygenRoutesEnum {
  AUTH_RENEW = '/authentication/renew',
  AUTH_LOGIN = '/authentication/login',
  AUTH_RESET_PASSWORD = '/authentication/reset-password',
  AUTH_RESET_PASSWORD_CONFIRM = '/authentication/reset-password-confirm',
  AUTH_VALIDATE_RESET_PASSWORD = '/authentication/validate-reset-token',
}

// API route prefixes
export enum apiPrefixEnum {
  API = 'api',
}
